<template>
  <div>
    <backButton />
    <template v-if="loading && !$store.getters.error">
      <preloader></preloader>
    </template>
    <template v-else>
      <v-form ref="form" lazy-validation>
        <v-card outlined class="mb-4">
          <v-card-title>
            {{ $t("General") }}
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" sm="5" md="4">
                <v-text-field
                  prepend-inner-icon="mdi-email"
                  :label="$t('Email')"
                  v-model="user.Email"
                  :rules="getRules('notEmptyEmailRule')"
                  required
                  validate-on-blur
                  class="mb-5"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  prepend-inner-icon="mdi-account"
                  :label="$t('First name')"
                  v-model="user.FirstName"
                  required
                  :rules="[
                    (v) => !!v || $t('First name is required'),
                    (v) =>
                      v.length >= 2 ||
                      $t('Minimum required characters is') + ' 2',
                  ]"
                  validate-on-blur
                  class="mb-5"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  prepend-inner-icon="mdi-account"
                  :label="$t('Last name')"
                  v-model="user.LastName"
                  required
                  :rules="[
                    (v) => !!v || $t('Last name is required'),
                    (v) =>
                      v.length >= 2 ||
                      $t('Minimum required characters is') + ' 2',
                  ]"
                  validate-on-blur
                  class="mb-5"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" class="ml-md-6 ml-sm-6">
                <v-select
                  prepend-inner-icon="mdi-web"
                  v-bind:items="languages"
                  :label="$t('Select a language')"
                  item-value="Language"
                  item-text="Language"
                  v-model="user.Language"
                  required
                  :rules="[(v) => !!v || $t('You must select an item')]"
                  validate-on-blur
                  class="max-w-select-lang mb-2"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
                <v-switch
                  flat
                  :ripple="false"
                  v-if="userIsAdministrator"
                  :label="$t('Administrator')"
                  v-model="user.Administrator"
                  hide-details="auto"
                  class="mt-2"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  :label="$t('User Manager')"
                  v-model="user.UserManager"
                  :disabled="user.Administrator"
                  @change="setAllRoles"
                  hide-details="auto"
                  class="mb-3 mt-2"
                ></v-switch>

                <v-btn
                  depressed
                  class="mb-3 ml-0 white--text"
                  color="primaryblue"
                  :disabled="!user.Email"
                  :loading="resendingMail"
                  @click="sendWelcomeEmail()"
                >
                  {{ $t("Resend welcome email") }}
                  <v-icon right>mdi-email</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4" v-if="checkForAccess">
          <v-card-title>
            {{ $t("Rights per module") }}
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" sm="2" class="mb-4">
                <v-select
                  v-if="websites && getWebsitesNotAssigned().length > 1"
                  :items="getWebsitesNotAssigned()"
                  :label="$t('Select a module')"
                  item-text="Name"
                  v-model="website"
                  prepend-inner-icon="mdi-laptop"
                  @change="changeWebsite()"
                  single-line
                  return-object
                  class="pt-0"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <create-edit-user-roles
                  v-if="!isLoadingRoles"
                  :userRolesProps="getWebitesUser"
                />
                <preloader v-else></preloader>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>

      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="saveUser"
        :loading="saving"
        :confirmLabel="$t('Save')"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import validation from "../../validation";

export default {
  data() {
    return {
      user: {
        Email: "",
        Administrator: false,
        Language: "",
        FirstName: "",
        LastName: "",
        Websites: [],
      },
      languages: this.$config.languages,
      websites: null,
      websiteToAdd: null,
      loading: false,
      resendingMail: false,
      saving: false,
      website: null,
      checkRead: false,
      checkWrite: false,
      checkDelete: false,
      userWebsitesRoles: {},
      userIsAdministrator: this.$auth.userIsAdministrator(),
      userIsUserManager: this.$auth.userIsUserManager(),
      isLoadingRoles: false,
    };
  },
  created() {
    this.getWebsites();
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),

    getWebsitesFormat() {
      if (!this.websites) {
        return [];
      }
      if (this.websites.length == 1) {
        return this.websites[0].Pages;
      }
      const website = this.getWebsite;
      if (!website) {
        return [];
      }
      return website.Pages;
    },
    getWebsite() {
      return this.websites.find((el) => el._id.$oid == this.website._id.$oid);
    },

    getWebitesUser() {
      if (!this.userWebsitesRoles || !this.website || this.isLoadingRoles) {
        return [];
      }
      if (!this.userWebsitesRoles[this.website._id.$oid].length) {
        let roles = [];
        const pages = this.getWebsitesFormat;
        pages.forEach((el) => {
          roles.push({ PageName: el.Name, Roles: [], PageId: el._id });
        });
        this.setUserRolesForNewOrCopy(roles);
      }
      return this.userWebsitesRoles[this.website._id.$oid];
    },
    checkForAccess() {
      if (
        !this.userIsAdministrator &&
        this.user.UserManager &&
        this.userIsUserManager
      ) {
        return false;
      } else if (this.userIsAdministrator && this.user.UserManager) {
        return true;
      } else if (this.user.Administrator) {
        return false;
      }
      return true;
    },
  },
  methods: {
    setUserRolesForNewOrCopy(roles) {
      this.userWebsitesRoles[this.website._id.$oid] = roles;
      return;
    },
    setAllRoles() {
      const keysArr = Object.keys(this.userWebsitesRoles);
      keysArr.forEach((key) => {
        if (this.$route.params.id && !this.userWebsitesRoles[key].length) {
          this.$request.get(
            "/userpageroles?userId=" +
              this.$route.params.id +
              "&websiteId=" +
              key,
            null,
            (res) => {
              this.isLoadingRoles = false;
              if (res.success) {
                if (res.data.length) {
                  this.userWebsitesRoles[key] = res.data;
                  if (this.user.UserManager) {
                    const includesOne = (val) => (val.Roles = [0, 1, 2]);

                    this.userWebsitesRoles[key].every(includesOne);
                  }
                }
              } else {
                const toaster = {
                  message: res.message,
                  timeout: false,
                  type: "ERROR",
                  toastClassName: ["toaster-color-error", "toaster-layout"],
                  bodyClassName: ["toaster-text-layout"],
                  icon: "mdi-alert-octagon",
                };
                this.$store.dispatch("setToaster", toaster);
              }
            }
          );
        } else {
          if (this.user.UserManager) {
            const includesOne = (val) => (val.Roles = [0, 1, 2]);

            this.userWebsitesRoles[key].every(includesOne);
          }
        }
      });
    },
    getRules(rule) {
      return validation.getValidation(rule);
    },
    goBack() {
      this.$router.go(-1);
    },
    getUser(id) {
      this.loading = true;
      let requests = ["/user/" + id];
      requests.push(
        "/userpageroles?userId=" + id + "&websiteId=" + this.website._id.$oid
      );
      this.$request.getAll(requests, null, (res) => {
        this.loading = false;
        if (res[1].success) {
          this.userWebsitesRoles[this.website._id.$oid] = res[1].data;
        } else {
          const toaster = {
            message: res[1].message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
        if (res[0].success) {
          this.user = res[0].data;
        } else {
          const toaster = {
            message: res[0].message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    saveUser() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        if (this.$route.params.isCopy) {
          this.$delete(this.user, "_id");
        }
        this.$request.put("/user", this.user, (res) => {
          this.saving = false;
          if (res.success) {
            const toaster = {
              message: res.message || this.$t("User saved successfully"),
              timeout: this.$config.hideMessageTimeout,
              type: "SUCCESS",
              toastClassName: ["toaster-color-success", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-check-circle",
            };
            this.$store.dispatch("setToaster", toaster);
            if (!this.$route.params.id || this.$route.params.isCopy) {
              this.sendWelcomeEmail(res.data._id.$oid);
            }
          }
          if (!res.success) {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          } else if (this.$route.params.isCopy || !this.$route.params.id) {
            this.updateUserRoles(res.data._id.$oid);
          }
        });
        if (!this.$route.params.isCopy && this.$route.params.id) {
          this.updateUserRoles(this.$route.params.id);
        }
      } else {
        const toaster = {
          message: this.$i18n.translate(
            "Please make sure all fields have a correct value"
          ),
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    },
    updateUserRoles(userId) {
      const keysArr = Object.keys(this.userWebsitesRoles);
      let keys = [];
      keysArr.forEach((key) => {
        if (this.userWebsitesRoles[key].length !== 0) {
          keys.push(key);
        }
      });
      keys.forEach((key, index) => {
        this.$request.put(
          "/userpageroles?userId=" + userId + "&websiteId=" + key,
          this.userWebsitesRoles[key],
          (res) => {
            this.saving = false;
            if (!res.success) {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
            if (index + 1 == keys.length) {
              this.$router.go(-1);
            }
          }
        );
      });
    },
    addUserWebsite() {
      if (this.websiteToAdd) {
        this.user.Websites.push({
          $oid: this.websiteToAdd,
        });
      }
    },
    removeUserWebsite(index) {
      this.user.Websites.splice(index, 1);
    },
    getWebsites() {
      this.$request.get("/websites", null, (res) => {
        if (res.success) {
          this.websites = res.data;
          res.data.forEach((element) => {
            this.userWebsitesRoles[element._id.$oid] = [];
          });
          this.website = this.selectedWebsite;
          if (this.$route.params.id) {
            this.getUser(this.$route.params.id);
          }
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    getWebsiteNameFromId(id) {
      if (this.websites) {
        for (var i = 0; i < this.websites.length; i++) {
          if (this.websites[i]._id.$oid == id) {
            return this.websites[i].Name;
          }
        }
      }
      return "";
    },
    getWebsitesNotAssigned() {
      var websites = [];

      if (this.websites && this.user) {
        for (var i = 0; i < this.websites.length; i++) {
          var websiteAssignedToUser = false;

          if (this.user.Websites) {
            for (var ii = 0; ii < this.user.Websites.length; ii++) {
              if (this.websites[i]._id.$oid == this.user.Websites[ii].$oid) {
                websiteAssignedToUser = true;
              }
            }
          }

          if (!websiteAssignedToUser) {
            websites[websites.length] = this.websites[i];
          }
        }
      }
      return websites;
    },
    sendWelcomeEmail(id) {
      this.resendingMail = true;
      const mailId = this.$route.params.id || id;
      this.$request.post("/sendwelcomeemail/" + mailId, null, (res) => {
        this.resendingMail = false;
        if (res.success) {
          const toaster = {
            message: res.message,
            timeout: this.$config.hideMessageTimeout,
            type: "SUCCESS",
            toastClassName: ["toaster-color-success", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-check-circle",
          };
          this.$store.dispatch("setToaster", toaster);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },

    changeWebsite() {
      if (!this.$route.params.id) {
        if (!this.userWebsitesRoles[this.website._id.$oid].length) {
          this.$set(this.userWebsitesRoles, this.website._id.$oid, []);
          this.websites.forEach((element) => {
            if (element._id.$oid == this.website._id.$oid) {
              element.Pages.forEach((el) => {
                let rolesForArray = {
                  PageName: el.Name,
                  Roles: [],
                  PageId: el._id.$oid,
                };
                if (this.user.UserManager) {
                  rolesForArray.Roles = [0, 1, 2];
                }
                this.userWebsitesRoles[this.website._id.$oid].push(
                  rolesForArray
                );
              });
            }
          });
        }
      } else {
        if (!this.userWebsitesRoles[this.website._id.$oid].length) {
          this.isLoadingRoles = true;
          this.$request.get(
            "/userpageroles?userId=" +
              this.$route.params.id +
              "&websiteId=" +
              this.website._id.$oid,
            null,
            (res) => {
              this.isLoadingRoles = false;

              if (res.success) {
                if (res.data.length) {
                  this.userWebsitesRoles[this.website._id.$oid] = res.data;
                }
              } else {
                const toaster = {
                  message: res.message,
                  timeout: false,
                  type: "ERROR",
                  toastClassName: ["toaster-color-error", "toaster-layout"],
                  bodyClassName: ["toaster-text-layout"],
                  icon: "mdi-alert-octagon",
                };
                this.$store.dispatch("setToaster", toaster);
              }
            }
          );
        }
      }
    },
  },
};
</script>

